<template>
  <div class="container-fluid">
    <div class="card">
      <div class="card-body">
        <div class="mb-2">
          <h3>
            ออเดอร์
          </h3>
        </div>
        <template v-if="!BoxID">
          <div class="row ">
            <div class="col-12 col-sm-4 col-xl-3">
              <b-form-group
                label="เริ่มต้น"
                label-for="vi-first-name"
              >
                <DatePicker
                  :date="inputs.dateStart"
                  :type="'start'"
                  :status="false"
                  @setDatePicker="onSetDatePicker"
                />
              </b-form-group>
            </div>
            <div
              class="col-12 col-sm-4 col-xl-3"
            >
              <b-form-group
                label="
            สิ้นสุด"
                label-for="vi-first-name"
              >
                <DatePicker
                  :date="inputs.dateEnd"
                  :type="'end'"
                  :status="false"
                  @setDatePicker="onSetDatePicker"
                />
              </b-form-group>
            </div>
            <div class="col-12 col-sm-4 align-self-center mb-1">
              <b-form-checkbox
                v-model="inputs.checkedList"
                checked="true"
                name="check-button"
                switch
                inline
              >
                ทั้งหมด
              </b-form-checkbox>

            </div>
          </div>
          <div class="row justify-content-end">
            <div class="col-12 col-sm-4 col-xl-3 ">
              <b-form-group
                label="การดำเนินงาน"
                label-for="vi-first-name"
              >
                <b-form-select
                  v-model="inputs.progress"
                  class="mb-1"
                  :options="opptionProgress"
                />
              </b-form-group>
            </div>
            <div class="col-12 col-sm-4 col-xl-3 ">
              <b-form-group
                label="สถานะ"
                label-for="vi-first-name"
              >
                <b-form-select
                  v-model="inputs.status"
                  class="mb-1"
                  :options="opption"
                />
              </b-form-group>
            </div>

          </div>
        </template>
        <template v-if="BoxID">
          <div class="row ">
            <div class="col-12 col-sm-4 col-xl-3">
              <b-form-group
                label="ล็อต/ลัง"
                label-for="vi-first-name"
              >
                <b-form-input
                  id="h-first-name"
                  ref="name"
                  v-model="textLotBox"
                  :readonly="true"
                />
              </b-form-group>
            </div>
          </div>
        </template>
        <b-table
          striped
          hover
          responsive
          class="type-relative"
          :per-page="inputsnode.perPage"
          :current-page="inputsnode.currentPage"
          :items="listOrder"
          :fields="fields"
          :filter="searchTerm"
          show-empty
          @filtered="onFiltered"
        >

          <template #cell(date)="data">
            <p>{{ data.item.created_at | formatDateSort }}</p>
          </template>
          <template #cell(price)="data">
            <p>{{ data.item.totalPrice.$numberDecimal | toCurrency }}</p>
          </template>
          <template #cell(status)="data">
            <p :class="data.item.status === 'i'?'text-primary':data.item.status === 'c'?'text-danger':data.item.status === 's'?'text-warning':data.item.status === 'a'?'text-info':'text-success'">
              {{ data.item.status === 'i'?'รอการจัดการ':data.item.status === 'c'?'ยกเลิก':data.item.status === 's'?'จัดส่ง':data.item.status === 'a'?'รอการชำระ':'อนุมัติ' }}
            </p>
          </template>
          <template #cell(statusprogress)="data">
            <b-button-group
              size="sm"
            >
              <b-button
                :variant="data.item.statusProgress === 'p'?'primary':'outline-primary'"
                :disabled="data.item.statusProgress === 'f' || BoxID?true:false"
                @click="onchangeProgress(data.item._id,'p')"
              >
                รอการจัดส่ง
              </b-button>
              <b-button
                :variant="data.item.statusProgress === 's'?'primary':'outline-primary'"
                :disabled="data.item.statusProgress === 'f' || BoxID?true:false"
                @click="onchangeProgress(data.item._id,'s')"
              >
                กำลังจัดส่ง
              </b-button>
              <b-button
                :variant="data.item.statusProgress === 'f'?'primary':'outline-primary'"
                :disabled="BoxID?true:false"
                @click="onchangeProgress(data.item._id,'f')"
              >
                จัดส่งเเล้ว
              </b-button>
            </b-button-group>
          </template>
          <template #cell(button)="data">
            <div class="d-grid d-md-block">
              <b-avatar
                size="3em"
                class="avatar-border-2 box-shadow-1"
                variant="primary"
                style="margin: 0px 3px;cursor:pointer"
              >  <font-awesome-icon
                style="width: 20px;"
                :icon="['fa', 'list']"
                @click="onClickManagement(data.item._id)"
              /></b-avatar>
              <b-tooltip
                :target="`tooltip-target-1${data.item._id}`"
                triggers="hover"
                placement="bottom"
              >
                จัดการ
              </b-tooltip>
              <b-avatar
                v-if="!BoxID"
                size="3em"
                style="margin: 0px 3px;cursor:pointer"
                class="avatar-border-2 box-shadow-1"
                variant="info"
              >
                <font-awesome-icon
                  style="width: 20px;cursor:pointer"
                  :icon="['fa', 'money-bill-1']"
                  @click="onClickPayment(data.item._id,data.item.status)"
                />
              </b-avatar>
              <b-tooltip
                :target="`tooltip-target-2${data.item._id}`"
                triggers="hover"
                placement="bottom"
              >
                ชำระ
              </b-tooltip>
              <b-avatar
                v-if="data.item.statusProgress === 'f' && !BoxID"
                size="3em"
                class="avatar-border-2 box-shadow-1"
                variant="warning"
                style="margin: 0px 3px;cursor:pointer"
              >
                <font-awesome-icon
                  style="width: 20px;cursor:pointer"
                  :icon="['fa', 'truck']"
                  @click="onclickTacking(data.item._id,data.item.transportNo, data.item.transportBy)"
                />
              </b-avatar>
              <b-tooltip
                :target="`tooltip-target-3${data.item._id}`"
                triggers="hover"
                placement="bottom"
              >
                จัดส่ง
              </b-tooltip>

            </div>
          </template>
          <template #empty>
            <div class="col-md-12 text-center m-1">
              <h4>ไม่พบข้อมูลการขาย</h4>
            </div>
          </template>
          <template #emptyfiltered>
            <div class="col-md-12 text-center m-1">
              <h4>ไม่พบข้อมูลการขาย</h4>
            </div>
          </template>
        </b-table>
        <b-card-body
          class="d-flex justify-content-between flex-wrap pt-0 mt-2"
        >

          <b-form-group
            label="Per Page"
            label-cols="6"
            label-align="left"
            label-size="sm"
            label-for="sortBySelect"
            class="text-nowrap mb-md-0 mr-1"
          >
            <b-form-select
              id="perPageSelect"
              v-model="inputsnode.perPage"
              size="sm"
              inline
              :options="inputsnode.pageOptions"
            />
          </b-form-group>

          <div>
            <b-pagination
              v-model="inputsnode.currentPage"
              :total-rows="inputsnode.totalRows"
              :per-page="inputsnode.perPage"
              first-number
              last-number
              prev-class="prev-item"
              next-class="next-item"
              class="mb-0"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </b-card-body>

      </div>

    </div>
    <b-modal
      id="modal-center"
      ref="my-modal"
      v-model="IsopenModalTack"
      hide-footer
      centered
      header-bg-variant="primary"
      title="จัดส่ง"
    >
      <Transport
        :data="datatack"
        @successTack="emitTack"
      /></b-modal>

  </div>
</template>
<script>
import { defineComponent } from '@vue/composition-api'
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BAvatar,
  BButtonGroup, BTooltip,
  BRow, BCol, BTable, BFormGroup, BFormSelect, BPagination, BModal, BButton, VBModal, BCardBody, BFormInput, BFormCheckbox, BBadge
  , BForm
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import moment from 'moment'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import DatePicker from '@/views/components/production/datePicker.vue'
import Transport from './transport.vue'

export default defineComponent({
  name: 'orderlist',

  components: {
    BTooltip,
    BAvatar,
    BButtonGroup,
    BRow,
    BCol,
    BTable,
    BButton,
    BFormSelect,
    BPagination,
    BCardBody,
    BFormGroup,
    BCardCode,
    BModal,
    VBModal,
    BFormInput,
    BForm,
    ValidationProvider,
    ValidationObserver,
    required,
    DatePicker,
    BFormCheckbox,
    BBadge,
    Transport
  },
  computed: {
    textLotBox () {
      return `${this.inputsBox.lot}/${this.inputsBox.box}`
    }
  },
  watch: {
    'inputs.checkedList': {
      handler (val) {
        this.getListOrder()
      }
    },
    'inputs.dateStart': {
      handler (val) {
        this.getListOrder()
      }
    },
    'inputs.dateEnd': {
      handler (val) {
        this.getListOrder()
      }
    },
    'inputs.status': {
      handler (val) {
        this.getListOrder()
      }
    },
    'inputs.progress': {
      handler (val) {
        this.getListOrder()
      }
    }

  },
  created () {
    this.getListOrder()
  },
  methods: {
    setReqiure () {
      this.$store.commit('order/SET_TOTAL_orderRequire')
    },
    onchangeProgress (code, status) {
      this.$store
        .dispatch('order/updatestatusProgress', { id: code, statusProgress: status })
        .then(response => {
          console.log(response)
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'สภานะดำเนินงาน',
              icon: 'CoffeeIcon',
              variant: 'success',
              text: response.data.message
            }
          })
          this.getListOrder()
        })
        .catch(error => {
          console.log(error)
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'สภานะดำเนินงาน ',
              icon: 'CoffeeIcon',
              variant: 'danger',
              text: error.data.message
            }
          })
        })
    },
    onclickTacking (code, transportNo, transportBy) {
      this.datatack.code = code
      this.datatack.transportNo = transportNo
      this.datatack.transportBy = transportBy

      this.IsopenModalTack = true
    },
    closeTacking () {
      this.IsopenModalTack = false
    },
    emitTack (e) {
      if (e) this.getListOrder()
      this.closeTacking()
    },
    onClickManagement (code) {
      this.$router.push({ path: `/ordermanagement/${code}` })
    },
    onClickPayment (code, status) {
      this.$router.push({ path: `/orderpayment/${code}/${status === 'a' ? 'add' : 'edit'}` })
    },
    onSetDatePicker (data) {
      if (data.type === 'start') this.inputs.dateStart = moment(data.val).format('YYYY-MM-DD'); else this.inputs.dateEnd = moment(data.val).format('YYYY-MM-DD')
    },
    onFiltered (filteredItems) {
      this.inputsnode.totalRows = filteredItems.length
      this.inputsnode.currentPage = 1
    },
    getListOrder () {
      if (this.BoxID) {
        this.$store.dispatch('order/getOrder', { BoxID: this.BoxID }).then(res => {
          console.log('999999', res)
          if (res.data.success) {
            const DataBox = res.data.items[1]
            this.inputsBox.lot = DataBox.lotId.code
            this.inputsBox.box = DataBox.no
            // eslint-disable-next-line prefer-destructuring
            this.listOrder = res.data.items[0]
            this.inputsnode.totalRows = this.listOrder.length
          }
        })
      } else {
        const status = this.inputs.status === '' ? ['i', 'a', 'n', 'c', 's'] : [this.inputs.status]
        const progress = this.inputs.progress === '' ? [] : [this.inputs.progress]

        this.$store.dispatch('order/getOrder', { startDate: this.inputs.checkedList ? '' : this.inputs.dateStart, endDate: this.inputs.checkedList ? '' : this.inputs.dateEnd, status, progress }).then(res => {
          if (res.data.success) {
            // eslint-disable-next-line prefer-destructuring
            this.listOrder = res.data.items[0]
            this.inputsnode.totalRows = this.listOrder.length
          }
        })
      }
    }
  },
  data () {
    return {
      IsopenModalTack: false,
      listOrder: [],
      searchTerm: '',
      BoxID: this.$route.params.box ? this.$route.params.box : null,
      inputsBox: {
        lot: '',
        box: ''
      },
      inputs: {
        dateStart: moment().format('YYYY-MM-DD'),
        dateEnd: moment().format('YYYY-MM-DD'),
        checkedList: false,
        status: '',
        progress: ''
      },
      opptionProgress: [{ value: '', text: 'ทั้งหมด' }, { value: 'p', text: 'รอจัดส่ง' }, { value: 's', text: 'กำลังจัดส่ง' }, { value: 'f', text: 'จัดส่งเเล้ว' }],
      opption: [{ value: '', text: 'ทั้งหมด' },
      //  { value: 'i', text: 'รอการจัดการ' },
        { value: 'n', text: 'อนุมัติ' }, { value: 'a', text: 'รอการชำระ' },
        // { value: 's', text: 'จัดส่ง' },
        { value: 'c', text: 'ยกเลิก' }
      ],
      fields: [
        {
          key: 'code', label: 'เลขที่', thStyle: 'min-width: 170px', tdClass: 'cursordefault'
        },
        {
          key: 'date', label: 'วันที่-เวลา', thStyle: 'min-width: 160px', tdClass: 'cursordefault p-0'
        },
        {
          key: 'price', label: 'ราคา', thStyle: 'min-width: 140px', tdClass: 'text-right cursordefault', thClass: 'text-right'
        },
        {
          key: 'status', label: 'สถานะ', thStyle: 'min-width: 130px', tdClass: 'cursordefault'
        },
        { key: 'statusprogress', label: 'สถานะดำเนินงาน', thClass: 'text-center', tdClass: 'p-0', thStyle: 'min-width: 300px' },
        { key: 'button', label: '', thStyle: 'min-width: 140px', tdClass: 'text-center cursordefault p-0', thClass: 'text-center' }

      ],
      inputsnode: {
        perPage: 5,
        pageOptions: [5, 10, 15],
        totalRows: 0,
        itemsPerPage: 10,
        currentPage: 1,
        startItem: 0,
        endItem: 10
      },
      datatack: {
        code: '',
        transportNo: '',
        transportBy: ''
      }
    }
  }
})
</script>
<style >
.modal-title {
  color: white;
}
.cursordefault{
  cursor: default;
}
.tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  /* color: #fff; */
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  top: -5px;
  left: 105%;
}
.hoverIc :hover{
  color: #7367f0;

}

/* .tooltipList:hover .tooltiptext {
  visibility: visible;
} */
</style>
