<template>
  <div>
    <div v-if="inputs.address === null">
      <div
        style="max-height: 340px;
    overflow-y: scroll;"
      >
        <div
          v-for="(data,index) in address"
          :key="index=index"
          class="row mb-1"
        >
          <div class="col-12">
            <div :class="data.status ?'card-adaresscommit':'card-adaress'">
              <div class="mb-1">
                <b-form-checkbox
                  v-model="data.status"
                  @change="onChangeDefaultAddress(data.status,data._id)"
                />
              </div>
              <p>{{ data.name }}<br>{{ `${data.address}  ${data.district}  ${data.city}  ${data.province} ${data.postal}` }}<br>{{ data.telephone }}</p>
            </div>
          </div>
        </div>
      </div>

      {{ inputs.address }}

    </div>
    <div v-if="inputs.address !== null">
      <div
        class="row mb-1"
      >
        <div class="col-12">
          <div :class="'card-adaresscommit'">
            <p>{{ inputs.address.name }}<br>{{ `${inputs.address.address}  ${inputs.address.district}  ${inputs.address.city}  ${inputs.address.province} ${inputs.address.postal}` }}<br>{{ inputs.address.telephone }}</p>
          </div>
        </div>
      </div>
    </div>
    <b-button
      class="mt-1"
      :variant=" 'primary'"
      @click="onClicksettingAddress"
    >
      {{ textbtAddress }}
    </b-button>
    <div class="row mt-1 mb-1">
      <div class="col-12">
        <validation-observer ref="transportRules">
          <validation-provider
            #default="{ errors }"
            name="bankName"
            rules="required"
          >
            <b-form-group
              label="ช่องทางการจัดส่ง"
              label-for="h-first-name"
              label-cols-md="4"
            >
              <b-form-select
                v-model="inputs.transportBy"
                :options="opptiontransport"
                autofocus
                :state="errors.length > 0 ? false : null"
              />
              <small
                v-if="errors.length > 0"
                class="text-danger"
              >โปรดระบุช่องทางการจัดส่ง</small>
            </b-form-group>
          </validation-provider>
          <validation-provider
            #default="{ errors }"
            name="bankName"
            rules="required"
          >
            <b-form-group
              label="เลขพัสดุ"
              label-for="h-first-name"
              label-cols-md="4"
            >
              <b-form-input
                v-model="inputs.transportNo"
                :state="errors.length > 0 ? false : null"
                placeholder="ระบุเลขแทรค"
              />
              <small
                v-if="errors.length > 0"
                class="text-danger"
              >โปรดระบุเลขแทรค</small>
            </b-form-group>
          </validation-provider>
        </validation-observer>
        <b-col cols="12 mt-2">
          <div class="d-flex justify-content-end">
            <b-button
              :variant="inputs.id === '' ? 'primary' : 'warning'"
              class="mr-1"
              @click="onClickCommit"
            >
              ยืนยัน
            </b-button>
            <b-button
              :variant="'danger'"
              class="mr-1"
              @click="closeTack"
            >
              ยกเลิก
            </b-button>
          </div>
        </b-col>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api'

import {
  BFormCheckbox,
  BRow,
  BCol,
  BTable,
  BFormGroup,
  BFormSelect,
  BPagination,
  BModal,
  BButton,
  VBModal,
  BCardBody,
  BFormInput,
  BForm
} from 'bootstrap-vue'

import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import transport from '@/data-json/transport.json'

export default defineComponent({
  name: 'TackingUpdate',
  components: {
    BRow,
    BFormCheckbox,
    BCol,
    BTable,
    BFormGroup,
    BFormSelect,
    BPagination,
    BModal,
    BButton,
    VBModal,
    BCardBody,
    BFormInput,
    BForm,
    ValidationProvider,
    ValidationObserver,
    required
  },
  props: ['data'],
  data () {
    return {
      onLoadpage: false,
      inputs: {
        id: this.data.code,
        statusProgress: 'f',
        transportNo: this.data.transportNo,
        transportBy: this.data.transportBy,
        address: null
      },
      address: [],
      opptiontransport: [],
      IDcus: '',
      Codecus: ''

    }
  },
  computed: {
    textbtAddress () {
      return this.inputs.address ? 'เปลี่ยนที่อยู่' : 'จัดการที่อยู่'
    }
  },
  watch: {
    'inputs.transportBy': {
      handler (val) {}
    }
  },
  created () {
    this.onLoad()
    this.onLoadDataorderCus()
  },
  methods: {
    onClicksettingAddress () {
      if (this.textbtAddress === 'เปลี่ยนที่อยู่') {
        this.ontemplateAdd()
      } else {
        this.onEditButton()
      }
    },
    ontemplateAdd () {
      this.inputs.address = null
    },
    onEditButton () {
      this.$router.push({ path: `/customer/${this.Codecus}/edit/orderlist` })
    },
    onChangeDefaultAddress (val, idAdress) {
      if (val) {
        this.$store.dispatch('customer/changeDefaultAddress', { id: this.IDcus, addressId: idAdress }).then(res => {
          if (res.data.success) {
            this.onLoadDataorderCus()
          }
        })
      }
    },
    closeTack () {
      this.$emit('successTack', false)
    },
    onClickCommit () {
      this.add()
    },
    add () {
      const ckAddress = this.address.filter(x => x.status === true)
      if (ckAddress.length > 0) {
        this.inputs.address = {
          name: ckAddress[0].name,
          telephone: ckAddress[0].telephone,
          province: ckAddress[0].province,
          city: ckAddress[0].city,
          district: ckAddress[0].district,
          address: ckAddress[0].address,
          postal: ckAddress[0].postal
        }
      }

      this.$refs.transportRules.validate().then(success => {
        if (success) {
          this.$store
            .dispatch('order/updatestatusLogis', this.inputs)
            .then(response => {
              console.log(response)
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'โปรโมชั่น',
                  icon: 'CoffeeIcon',
                  variant: 'success',
                  text: response.data.message
                }
              })
              this.$emit('successTack', true)
            })
            .catch(error => {
              console.log(error)
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'โปรโมชั่น',
                  icon: 'CoffeeIcon',
                  variant: 'danger',
                  text: error.data.message
                }
              })
            })
        }
      })
    },
    onLoad () {
      this.opptiontransport = [{ value: '', text: 'โปรดระบุช่องทางจัดส่ง' }]
      transport.RECORDS.forEach(data => {
        this.opptiontransport.push({
          value: data.transportBy,
          text: data.transportBy
        })
      })
    },
    onLoadDataorderCus () {
      this.$store
        .dispatch('order/getorderpayment', { id: this.inputs.id })
        .then(res => {
          if (res.data.items[0].customerId.address.length > 0) this.address = res.data.items[0].customerId.address
          this.IDcus = res.data.items[0].customerId._id
          this.Codecus = res.data.items[0].customerId.code
          if (!this.onLoadpage) {
            if (res.data.items[0].address.length > 0) this.inputs.address = res.data.items[0].address
          }
          this.onLoadpage = true
        })
    }
    // loaderAddress (res) {
    //   this.inputs.address = res.data.items[0].address
    // }
  }
})
</script>
<style scoped>
.card-adaresscommit {
  border: 3px dashed #7367f0;
  min-height: auto;
    padding: 1rem;
}
.card-adaress {
    padding: 1rem;
  border: 3px dashed #b8c2cc;
}
</style>
